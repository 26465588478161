.primary-navigation-mobile {
    text-align: left;
}
.primary-navigation-mobile > li {
    border: 0px !important;
    padding: 10px 0 10px 0;
}

.primary-navigation-mobile > li ul li:last-child {
    border-bottom: 1px solid #d3d3d3;
}

.primary-navigation-mobile > li ul li {
    border-bottom: 1px solid #fff;
    background-color: #f4f4f4;
    padding: 10px 0;
}

.primary-navigation-mobile > li ul li a::after {
    display: none;
}

.primary-navigation-mobile li > a {
    padding-left: 22px !important;
    letter-spacing: 2px;
}

.primary-navigation-mobile li > a::after {
    //font-family: "Material Icons" !important;
   // content: "\e145" !important;
    right: 21px;
    font-family: 'FontAwesome'!important;
    content: "\f067"!important;

}
.mobile-navigation-container {
    margin-left: 0px;
}

.primary-navigation-mobile .accountMenuItem a::after,
.primary-navigation-mobile .wishlistMenuItem a::after,
.primary-navigation-mobile .contactMenuItem a::after,
.primary-navigation-mobile .findstoreMenuItem a::after {
    display: none;
}

.primary-navigation-mobile .accountMenuItem,
.primary-navigation-mobile .wishlistMenuItem,
.primary-navigation-mobile .contactMenuItem,
.primary-navigation-mobile .findstoreMenuItem {
    text-align: center;
}

.primary-navigation-mobile .accountMenuItem  a,
.primary-navigation-mobile .wishlistMenuItem a,
.primary-navigation-mobile .contactMenuItem  a,
.primary-navigation-mobile .findstoreMenuItem a {
    padding-left: 0px !important;
}

.primary-navigation-mobile li.open > a::after {
    font-family: "FontAwesome" !important;
    content: "\f068" !important;
    font-size: 12px !important;
    right: 28px;
}
