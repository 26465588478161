/*------------------------------------*\
    # COMPONENT OVERRIDES
\*------------------------------------*/

/**
 * Add local component overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

// The general font used for Mountfords is wider than the font used in any of the other designs. As a result, the size
// swatches in the filters and quick view will touch the edges of the box when display a half size. Making just this
// font smaller will allow for the size to fit within the box still, whilst also retaining some whitespace padding
.swatch-attribute.filter-size .swatch-option.text {
  font-size: 0.75em !important;
}

//The Mountfords is much wider than it is higher, therefore it requires some custom responsive logo rules
.site-header__logo-container {
  width: inherit !important;
  @media (max-width: 410px) {
    width: 110px !important;
  }
}

// The icon font used for Mountfords, Mollini and Cinori is 'bigger' than the Material Icons font, so where the font
// is being used or its icons, the font size needs to be reduced.
.site-header__mobile-top .site-header__mobile-links li a:before {
  font-size: 21px !important;
  line-height: 1.3em !important;
}

.quickview__view-full-details-container {
  .quickview__view-full-details-button {
    @include button-text;
  }
}

// PDP Recently Viewed Override
@media (max-width: 960px) {
  .recently-viewed-pdp-container {
    display: none !important;
  }
}

/* Sort By Options Fixes */
@media (max-width: 959px) {
  .catalog-category-view .toolbar {
    padding: 0 10px;
  }
  .toolbar__sorter {
    width: 65%;
  }
  .toggle-refine-filters-container {
    width: 35%;
  }
  .toggle-refine-filters-container .toggle-refine-filters,
  .toolbar__sorter .sorter-container select {
    font-size: 14px;
  }
  .toolbar__sorter .sorter-container select {
    padding: 0 27px 0 13px;
  }
}

/* Mega Menu Fix */
ul.primary-navigation {
  height: 49px;
}

/* Mobile Fixes */
@media (max-width: 640px) {
  .catalogsearch-result-index .main-content h1 {
    font-size: 18px;
  }
}

/* Sticky Nav Fix */
.navigation-bar-scrolled .minicart__summary .minicart__count {
  right: 63px;
  top: 7px;
}
.navigation-bar-scrolled .site-header__logo-container {
  padding: 15px 0 0 10px;
}

/* MMI-557 */
@media (max-width: 960px) {
  .category-description {
    display: none;
  }
}
/* MMI-626 */
.recently-viewed-pdp-container {
  background-color: #fff;
}

.slideout-open .slideout-menu {
  width: 100%;
  border: 0px;
  margin-top: 85px;
}

.site-header__mobile-top .site-header__mobile-links li a:before {
  color: $color-mountfords-brown;
}

.site-header__mobile-links .minicart__summary .minicart__count {
  background-color: $color-mountfords-brown;
  width: 10px;
  height: 10px;
  right: 5px;
  top: 12px;
}

.site-header__mobile-top .navigation-bar-mobile__toggle:before {
  color: $color-mountfords-brown;
}

@media (min-width: 920px) {
  .topInfoBarContainer .wrapper {
    max-width: 85rem;
  }
}

.navigation-bar-mobile__toggle.open::before {
  // font-family: "Material Icons" !important;
  // content: "\e5cd" !important;
  margin-top: -2px;
  font-size: 27px !important;
  font-family: "FontAwesome";
  content: "\f00d";
}

.navigation-bar .navigation-bar__wrapper {
  padding-left: 12px;
  padding-right: 12px;
}

/* Temporary fixes moved from sitewide banner */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}

@media (max-width: 960px) {
  .home-main-panels {
    margin-top: 0px !important;
  }
  .catalogsearch-result-index .main-content h1.fh_search_term {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 960px) {
  .full-site-banner {
    margin-top: 142px;
  }
}
.campaign-site-banner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.campaign-site-banner img {
  width: 100%;
}

/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
  display: none;
}
.product-info__main .product-addto-links .towishlist span {
  top: 1px;
  position: relative;
} 

/* Fix for sale badge */
.wrapper--gutter {
  position: relative;
}
@media (max-width: 750px) {
  .product-info .badge-container {
    margin-left: 0px !important;
  }
}
@media (max-width: 960px) {
  .product-info .badge-container {
    top: 10px !important;
  }
}
