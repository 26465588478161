@media (min-width: 333px) {
    .mobilenavigation-bar-scrolled .site-header__mobile-top .site-header__mobile-links li + li {
        padding-left: 5px !important;
    }
}

@media (max-width: 960px) {
    .site-header__mobile {
        padding: 0px;
        padding-bottom: 30px;
        margin-bottom: 15px;

        .topInfoBar {
            background-color: $color-mountfords-cream;
        }
    }

    .site-header__mobile .topInfoBar li {
        color: $color-mountfords-brown;
    }

    .site-header__mobile-top {
        padding: 0px;
        border-bottom: 1px solid #ffffff;

        .site-header__logo-container {
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;
            width: 200px !important;
            margin-top: 4px !important;
        }
    }

    .site-header__mobile-top .site-header__logo-container img {
        max-width: 190px;
    }
    .site-header__mobile-top .mobile-button-container {
        padding-left: 20px;
    }

    .site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle {
        padding-top: 5px;
        outline: 0;
    }

    .site-header__mobile-top .mobile-header-search-toggle-container .mobile-header-search-toggle:before {
        background: url(/media/wysiwyg/Mountfords/icons/search.png) no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
        outline: 0;
        content: "";
        display: inline-block;
    }

    .site-header__mobile-links .wishlist {
        display: none !important;
    }

    .site-header__mobile-top .site-header__mobile-links li {
        padding-left: 5px !important;
    }

    .site-header__mobile-top .site-header__mobile-links {
        padding-right: 20px;
        margin: 0px;
    }

    .site-header__mobile-top .minisearch {
        display: block;
        margin: 15px 20px 15px 20px;
        width: auto;

        .minisearch__control--submit {
            background-size: contain;
            top: 80px;
            position: absolute;
            right: 22px;
            max-width: 26px;
            padding-top: 5px;
            padding-left: 13px;
            max-width: 50px;
            width: 50px;
            height: 41px;
            outline: 0;
            content: "";
            display: block;

            :after {
                content: "";
                display: block;
            }
        }

        .minisearch__control--text {
            border-radius: 5px;
            outline: 0;
        }
    }

    .minisearch .minisearch__control--submit:after {
        background: url("/media/wysiwyg/Mountfords/icons/search.png") no-repeat;
        width: 26px;
        height: 30px;
        background-size: contain;
        outline: 0;
        content: "";
        display: block;
    }

    .focused-input .minisearch__control--submit {
        background-color: $color-mountfords-brown;
        border-radius: 0px 5px 5px 0px;
        // right: 22px !important;
        //height: 42px !important;
    }

    .focused-input .minisearch__control--submit:after {
        background: url("/media/wysiwyg/Mountfords/icons/search-white.png") no-repeat;
        max-width: 26px;
        height: 30px;
        background-size: contain;
        outline: 0;
    }
    .site-header__mobile-top .site-header__mobile-links .minicart a.minicart__summary:before {
        background: url("/media/wysiwyg/Mountfords/icons/bag.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }

    .site-header__mobile-top .site-header__mobile-links .minicart a.cart-has-item:before {
        background: url("/media/wysiwyg/Mountfords/icons/bag-active.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }

    .site-header__mobile-top .site-header__mobile-links .minicart__summary .minicart__count {
        background-color: transparent;
        right: 8px;
        top: 11px;
        font-size: 11px;
    }

    .site-header__mobile-top .minisearch .minisearch__control--text::placeholder {
        text-transform: lowercase;

        font-family: inherit;
        font-size: 14px;
        letter-spacing: 2px;
    }

    #mobile_header_search_mini_form .minisearch__control--text::placeholder {
        text-transform: lowercase;

        font-family: inherit;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .site-header__mobile-top.mobilenavigation-bar-scrolled {
        padding-bottom: 10px !important;
    }
}

@media (min-width: 960px) {
    .siteHeaderBannerContent {
        text-align: center;
        padding-top: 25px;
        padding-bottom: 0px;
    }

    .site-header__logo-container {
        max-width: 400px;

        img {
            max-width: 320px !important;
        }
    }
    .navigation-bar__group--account > li {
        width: 35px !important;
    }
    .navigation-bar__group--account .wishlist a:before {
        background: url("/media/wysiwyg/Mountfords/icons/wishlist.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }
    .navigation-bar__group--account .wishlist a.wishlist-has-item:before {
        background: url("/media/wysiwyg/Mountfords/icons/wishlist-active.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }
    .navigation-bar__group--account .wishlist .counter {
        background-color: transparent;
        right: 14px;
        top: 11px;
        width: 15px;
        height: 15px;
        font-size: 9px;
    }

    .navigation-bar__group--account .minicart a.minicart__summary:before {
        background: url("/media/wysiwyg/Mountfords/icons/bag.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }

    .navigation-bar__group--account .minicart a.cart-has-item:before {
        background: url("/media/wysiwyg/Mountfords/icons/bag-active.png") no-repeat;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        content: "";
    }

    .navigation-bar__group--account .minicart__summary .minicart__count {
        background-color: transparent;
        right: 15px;
        top: 8px;
        width: 15px;
        height: 15px;
        font-size: 9px;
    }
    .site-header__strip .topInfoBarContainer .stockistsMenuItem {
        display: inline-block;
        width: auto;
        text-align: left;
        margin: 0px;
        padding: 7px 0;
    }
    .site-header__strip .topInfoBarContainer .stockistsMenuItem a:before {
        background: url("/media/wysiwyg/Mountfords/icons/store-locator.png") no-repeat;
        width: 26px;
        height: 27px;
        background-size: contain;
        outline: 0;
        content: "";
        display: inline-block;
    }
    .site-header__strip .topInfoBarContainer .stockistsMenuItem .nav-store-locator-text {
        vertical-align: super;
    }
}

.mobile-navigation-container.slideout-menu {
    padding-top: 0;
}
.mobile-navigation-container .minisearch {
    background-color: $color-mountfords-lightbrown;
    height: 72px;
}
.mobile-navigation-container .minisearch__control--text {
    background-color: $color-mountfords-lightbrown;
    padding: 5px 50px 5px 20px;
    color: $color-mountfords-brown;
    margin-top: 15px;
    outline: 0;
}

.mobile-navigation-container .minisearch__control--text::placeholder {
    color: #b6b99f;
}

.mobile-navigation-container .minisearch .minisearch__control--submit:after {
    position: absolute;
    right: 21px;
    top: 28px;
    height: 20px;
}

.mobile-menu-shown #js-aligent_fredhopper-autocomplete-results {
    position: fixed !important;
    z-index: 5;
    top: 150px !important;
}

.site-header__mobile-top .minisearch.hidden {
    display: none;
    max-height: initial;
}

.site-header__mobile-top .minisearch {
    position: relative;
}

.site-header__mobile-top .minisearch .minisearch__control--submit {
    top: 0;
    right: 0;
}

@media (max-width: 960px) {
    #js-aligent_fredhopper-autocomplete-results {
        max-height: 500px;
        overflow-y: auto;
    }
}
// MMI-1163 search fix on cart page for mobile
@media (max-width: 960px) {
    .checkout-cart-index .mobile-header-search-toggle {
        display: inline-block !important;
    }

    .checkout-cart-index .minisearch__control {
        display: none;
    }
}

.site-header__strip .account-navigation {
    width: auto;
    display: flex;
}
