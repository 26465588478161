.minicart {
    .minicart__details {
        right: -6px;
        border: 1px solid $color-mountfords-brown;

        .block-content {
            .subtotal {
                color: #000;
                text-align: initial;
                display: flex;
                flex-wrap: nowrap;
                margin-bottom: 20px;
                border-top: 1px solid #00000029;
                border-bottom: 1px solid #00000029;
                padding: 10px 5px;
                span.label {
                    flex: 0 0 50%;
                    font-size: 14px;
                    text-transform: uppercase;
                    text-align: left;
                    margin-top: 0 !important;
                }

                .price-container {
                    flex: 0 0 50%;
                    font-size: 14px;
                    text-transform: uppercase;
                    text-align: right;
                    padding: 0;
                }
            }
            .actions {
                .primary {
                    .action.primary {
                        width: 100%;
                        background-color: $color-mountfords-brown;
                        border: 0px;
                    }
                }
                .secondary .viewcart {
                    text-align: center;
                    border: 1px solid $color-mountfords-brown;
                    margin: 0;
                    padding: 10px;
                    margin-top: -10px;
                    span {
                        font-size: 15px;
                        color: $color-mountfords-brown;
                    }
                }
            }
        }
    }
}

.minicart-items {
    /* ol */
    .product-item {
        /* li */
        border: 0px;
        .product-item-details {
            border-bottom: 2px solid #00000029;
            padding-bottom: 5px;
            margin-left: 15px;
            padding-right: 20px;
            .product-item-name {
                a {
                    font-size: 14px;
                    letter-spacing: 0.5px;
                }
            }
            .product-item-pricing {
                margin-top: -15px;
                margin-bottom: 20px;
                .price-container > * {
                    font-size: 14px;
                    float: right;
                    color: #000;
                    text-align: right;
                }
            }
            .details-qty {
                height: 21px;
                label {
                    width: auto;
                    text-align: left;
                    font-size: 14px;
                    text-transform: capitalize;
                    padding: 0;
                }
                input {
                    margin-left: 5px;
                    width: auto;
                    text-align: left;
                    font-size: 14px;
                }
            }
            .product.actions {
                .action.delete {
                    top: -15px;
                }
            }
        }
    }
}
.product-item-details {
    .product.options {
        .list {
            .label {
                width: auto;
                text-align: left;
                font-size: 14px;
                text-transform: capitalize;
                padding: 0;
            }
            .values {
                color: #000;
                margin-left: 5px;
                width: auto;
                text-align: left;
                font-size: 14px;
            }
        }
    }
}

.up-arrow {
    border-bottom: 5px solid $color-mountfords-brown;
}

.navigation-bar-scrolled .minicart .minicart__details {
    right: -16px;
}
