.site-header__strip {
    padding: 8px 8px 0 8px;
}

.site-header__strip .topInfoBarContainer ul.header.links .authorization-link a:after,
.authorization-link a[href*="logout"]:before {
    display: none !important;
}

.site-header__strip .customer-welcome .customer-name:before,
.site-header__strip .authorization-link a[href*="login"]:before {
    content: "";
    display: inline-block;
    background-image: url(/media/wysiwyg/Mountfords/icons/account.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    width: 26px;
    height: 26px;
}

.logged-in-user .site-header__strip .topInfoBarContainer ul.header.links .authorization-link a,
.logged-in-user .site-header__strip .topInfoBarContainer ul.header.links .authorization-link a span {
    display: none !important;
}

@media (min-width: 960px) {
    .topInfoBarContainer ul.header.links .authorization-link a {
        text-decoration: none;
        float: left;
        letter-spacing: 1px;
        margin-right: 10px;
    }

    .customer-welcome a {
        letter-spacing: 1px;
        margin-right: 10px;
    }
    .site-header__strip .topInfoBarOuterContainer {
        display: flex;
        justify-content: flex-start;
    }
    .site-header__strip .topInfoBarContainer ul.header.links {
        float: left;
        text-align: center;
        //margin: 0px 10px 0px 0px;
    }
    .topSiteInfo {
        padding: 10px;
        letter-spacing: 1px;
        padding-top: 12px;
        width: fit-content;
        text-align: center;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    #js-aligent_fredhopper-autocomplete-wrapper {
        width: auto;
        right: 0px;
        margin: 0;
        padding: 0;
        text-align: start;
        margin-right: 10px;
      margin-left: auto;
    }
    #js-aligent_fredhopper-autocomplete-search {
        top: 6px;
        position: relative;
        color: $color-mountfords-brown;
    }
    #js-aligent_fredhopper-autocomplete-wrapper .minisearch__control--submit {
        right: 0px;
        top: 9px;
    }
    .account-navigation {
        max-width: 110px;
        width: 100%;
        float: right;
        text-align: end;
    }

    #js-aligent_fredhopper-autocomplete-search::placeholder {
        color: $color-mountfords-brown;
        font-family: inherit;
        font-size: 14px;
        opacity: 1;
    }
}

@media (max-width: 1125px) {
  .topSiteInfo {
    padding-top: 0px !important;
    max-width: 275px;
  }
}