.primary-navigation {
    text-align: center;
}

.primary-navigation .level0.level-top {
    text-align: left;
}

.primary-navigation .level0.level-top > a:after {
    display: none;
}

.navigation-bar {
    border-top: 1px solid #fff;
    border-bottom: 1px solid $color-mountfords-cream;
    padding: 10px;
    .primary-navigation {
        li {
            font-size: 16px;
            letter-spacing: 2.5px;
            .level-top {
                :hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
