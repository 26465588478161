.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-submit,
.product-notify-me-thanks-wrapper .product-notify-me-continue-button {
    background-color: $color-mountfords-orange;
    padding: 5px;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-button {
    padding: 4px;
}

.product-notify-me-wrapper .product-notify-me-btn {
    height: 40px;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
    padding: 0px;
    height: 40px;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-info {
    font-weight: inherit;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-info .boldwrapper {
    font-weight: bold;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
    text-align: center;
  } 