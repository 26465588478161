$searchInputHeight: 43px;
$twoColumnsDisplayBreakpoint: 630px;
$threeColumnsDisplayBreakpoint: 1000px;
$fourColumnsDisplayBreakpoint: 1229px;

.stockists-search {
    text-align: center;
    margin-bottom: 50px;
    position: relative;

    .stockists-search__heading {
        @include heading1;
        margin-bottom: 50px;
    }

    .stockists-search__text-input {
        @include functional-text-black;
        width: 100%;
        max-width: 408px;
        height: $searchInputHeight;
        border: 1px solid #000000;
        padding: 0 20px;
        margin-right: 14px;
        background-color: #FFFFFF;
        margin-bottom: 10px;

        @media (min-width: $twoColumnsDisplayBreakpoint) {
            margin-bottom: 0;
        }
    }

    .stockists-search__button {
        @include functional-text-white;
        background-color: $color-shop-accent;
        width: 195px;
        height: $searchInputHeight;
    }

    .stockists-search__loader {
        display: table;
        position: absolute;
        bottom: -435px;
        z-index: 1000;
        width: 100%;
        height: 385px;
        background-color: rgba(255, 255, 255, 0.9);

        .loading {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .stockists-wait-for-page__loader {
        display: table;
        position: absolute;
        bottom: -80px;
        z-index: 1000;
        width: 100%;
        height: 124px;
        background-color: rgba(255, 255, 255, 0.9);

        @media (max-width: $twoColumnsDisplayBreakpoint) {
            height: 180px;
        }

        .loading {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.stockists__map {
    height: 385px !important; /* !important because the height of the map is hard coded in vendor/aligent/stockists/view/frontend/templates/index/map.phtml */
}

.stockists-directory-toolbar {
    display: none; /* Unable to use XML to hide this element, as there is JS that is relying on it being there */
}
.stockists-directory-list { /* ul */
    font-size: 0; /* Hack to remove any browser added white space between the sibling elements */
    list-style: none;
    margin: 0 auto;
    padding: 0 20px 0 0;
    background-color: #FFFFFF;

    & > div {
        font-size: medium; /* IE Fix */
        font-size: initial; /* Reset font size back to normal after the font-size: 0 hack in the parent container */
        text-align: center;
        min-width: 300px;
        vertical-align: top;
        display: inline-block;

        .directory-item__distance {
            @include functional-text-white;
            display: none;
            background-color: #000000;
            width: 100px;
            text-align: center;
            margin: -32px auto 20px;
        }

        .directory-item__heading {
            @include large-text;
        }

        .stockists-directory__item { /* li */
            display: inline-block;
            background-color: #FFFFFF;
            width: 100%;
            height: 100%;
            text-align: left;

            .directory-item {
                padding: 20px;

                .directory-item__phone {
                    padding-left: 32px;
                    padding-bottom: 20px;
                    line-height: 26px; /* This is to line up the actual text of the number with the icon */
                    text-align: left;
                    text-decoration: none;

                    display: flex;
                    flex: 0 0 auto;
                    flex-direction: row;
                    align-items: flex-start;

                    &:before {
                        @include icon-font;
                        content: '\e551';
                        margin-left: -32px;
                        margin-right: 4px;
                        vertical-align: top;
                    }
                }

                address {
                    font-style: normal;
                    padding-left: 32px;
                    vertical-align: top;
                    line-height: 20px; /* This is to reduce the gap that will appear between the top line and following lines due to the icon in :before */
                    text-align: left;

                    &:before {
                        @include icon-font;
                        content: '\e55f';
                        margin-left: -32px;
                        vertical-align: top;
                    }

                }

                .show-on-map {
                    @include small-text-black;
                    padding-left: 32px;
                    text-decoration: none;
                    display: block;

                    .show-on-map-text {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .directory-item__opening-times-container {
        @include small-text-black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        .directory-item__opening-times-trigger {
            cursor: pointer;
            position: relative;
            text-align: left;
            padding: 5px 8px;

            &:after {
                position: absolute;
                right: 8px;
            }
            &.closed:after {
                content: "+";
            }
            &.open:after {
                content: "-";
            }
        }

        .directory-item__opening-times {
            padding: 3px 12px;
            text-align: left;
            list-style: none;
            margin: 0;
        }
    }

    /**
     * Set up the layout of the store location search results for the 1, 2, 3 and 4 column options
     */

    /* 1 column layout */
    & > div {
        width: 100%;
        padding-left: 15px;
    }

    /* 2 column layout */
    @media (min-width: $twoColumnsDisplayBreakpoint) {

        & > div {
            width: 50%;
        }
    }

    /* 3 column layout */
    @media (min-width: $threeColumnsDisplayBreakpoint) {

        & > div {
            width: 33.33%;
            padding-left: 15px;
        }
    }

    /* 4 column layout */
    @media (min-width: $fourColumnsDisplayBreakpoint) {

        & > div {
            width: 25%;
            padding-left: 15px;
        }
    }

    /**
     * End of column layout setup
     */
}

.stockists-directory-list--ready {
    opacity: 1;
}

/* Styling for the location bubble */
.stockists__map {
    .location-info-window {
        .get-directions {
            margin-top: 10px;
            display: block;
            padding-left: 0;
        }
    }
}

/* MMI-1344 */
.stockist-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.stockist-wrapper .stockist-search-wrapper {
    flex: 0 0 100%;
}

.stockist-wrapper .stockist-directory-wrapper {
    flex: 0 0 35%;
}

.stockist-wrapper .stockist-map-wrapper {
    flex: 0 0 65%;
}
.stockists-directory__item .stockist-accordion-body {
    position: relative;
    margin: 10px 10px;
    height: 0;
    text-align: justify;
    overflow: hidden;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}

.stockists-directory__item .stockist-accordion-heading {
    position: relative;
    padding: 0;
    cursor: pointer;
}
.stockists-directory__item .stockist-accordion-heading::before {
    content: '+';
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
}

.stockists-directory__item .directory-item.active .stockist-accordion-body {
    height: initial;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
    padding-top: 10px;
}

.stockists-directory__item .directory-item.active .stockist-accordion-heading::before {
    content: '-';
}

.stockists-directory-list {
    opacity: 1;
}

.stockists-directory-list > div {
    width: 100%;
    border-top: 1px solid $color-shop-accent;
    padding-left: 0 !important;
    margin-top: 0 !important;
}

.stockists-directory-list > div:last-child {
    border-bottom: 1px solid $color-shop-accent;
}

.stockists-directory-list > div .directory-item__heading {
    margin-bottom: 0;
}

.stockists-directory__item .stockist-accordion-body {
    margin: 0;
}

.stockists-directory-list > div .stockists-directory__item .directory-item {
    padding: 10px;
}

.stockists-directory-list > div .directory-item__heading {
    font-size: 20px;
}

.stockists-directory-list > div .stockists-directory__item .directory-item address {
    margin-bottom: 20px;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .directory-item__phone:before,
.stockists-directory-list > div .stockists-directory__item .directory-item address:before {
    color: $color-shop-accent;
}

.stockists-directory__item .stockist-accordion-body .directory-item__actions {
    display: flex;
    flex-wrap: nowrap;
}

.stockists-directory__item .stockist-accordion-body .directory-item__actions > a {
    flex: 0 0 50%;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .show-on-map {
    text-transform: capitalize;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .show-on-map .show-on-map-arrow {
    display: none;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .show-on-map {
    padding-left: 0;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .view-on-map:before {
    font-family: "FontAwesome";
    font-size: 22px;
    line-height: 1em;
    content: '\f279';
    vertical-align: top;
    padding-right: 5px;
    color: $color-shop-accent;
}

.stockists-directory-list > div .stockists-directory__item .directory-item .fa-directions {
    font-size: 22px;
    line-height: 1em;
    vertical-align: top;
    padding-right: 5px;
    color: $color-shop-accent;
}

.stockists-directory-list .directory-item__opening-times-container {
    border: none;
    margin-bottom: 10px;
    margin-top: 0 !important;
}

.stockists-directory-list .directory-item__opening-times-container .fa-clock{
    vertical-align: top;
    display: inline-block;
    font-size: 20px;
    color: $color-shop-accent;
}

.stockists-directory-list .directory-item__opening-times-container .directory-item__opening-times {
    padding: 0 5px;
    color: #666;
}

.directory-item__opening-times-container > div {
    display: inline;
}

.stockists-directory-list .directory-item__opening-times-container .collapse {
    height: initial;
    overflow: initial;
    visibility: initial;
    display: inline-block;
}

@media (max-width: 980px){
    .stockist-wrapper .stockist-map-wrapper {
        flex: 0 0 63%;
    }
}

@media (max-width: 960px) {
    .stockist-wrapper .stockist-directory-wrapper {
        flex: 0 0 100%;
    }
    .stockist-wrapper .stockist-map-wrapper {
        flex: 0 0 100%;
    }
    .stockists-directory-list > div .stockists-directory__item .directory-item address:before {
        content: '\e55f' !important;
    }
}
