.primary-navigation .level0.level-top > a:after {
    display: none;
}

.primary-navigation .level0.submenu {
    border: 1px solid $color-mountfords-brown;
}

.navigation-bar {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #e7e0d8;
    padding: 10px;
    padding-bottom: 0px;
    .primary-navigation {
        li {
            font-size: 15px;
            letter-spacing: 2.5px;
            .level-top {
                :hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.navigation-bar__group--account > li {
    width: 50px;
}

.navigation-bar__group--account li a:before {
    color: $color-mountfords-brown;
}

.navigation-bar-scrolled {
    .site-header__logo-container img {
        max-height: 30px;
    }
    .site-header__logo-container {
        max-height: 49px;
        height: 49px !important;
        padding: 15px 0 0 10px;
        position: relative;
    }
    .minicart__summary .minicart__count {
        width: 15px;
        height: 15px;
    }
}

.navigation-bar-scrolled #primary-navigation {
    margin-left: 35px;
}

.navigation-bar-scrolled .primary-navigation .nav-6 {
    display: inline-block !important;
}

.navigation-bar-scrolled .header-icons-wrapper {
    padding-top: 5px;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group > li {
    margin: 0px !important;
}

.navigation-bar-scrolled .navigation-bar__group--account li a.minicart__summary .minicart__label {
    display: none;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart {
    border-left: 0px;
}

.navigation-bar__group--account li.wishlist .nav-wishlist-text,
.navigation-bar__group--account li.minicart .minicart__label {
    display: none !important;
}

.navigation-bar-scrolled .navigation-bar__group--account .wishlist .counter {
    width: 15px;
    height: 15px;
    font-size: 9px;
    right: 5px;
    top: 8px;
}

.navigation-bar-scrolled .minicart__summary .minicart__count {
    width: 15px;
    height: 15px;
    font-size: 9px;
    right: 6px;
    top: 12px;
}

.navigation-bar__group--account .minicart__summary .minicart__count {
    width: 15px;
    height: 15px;
}

.account-navigation {
    max-width: 75px !important;
}

.navigation-bar-scrolled .account-navigation {
    max-width: 110px !important;
}

@media (min-width: 960px) {
    .navigation-bar-scrolled .navigation-bar__wrapper {
        max-width: 90% !important;
    }
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
        top: 4px;
    }
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-results {
        top: 32px;
    }
    .navigation-bar-scrolled .fh-search-suggest-wrapper {
        //display: block !important;
        padding: 0px;
        width: auto;
        right: 0px;
        margin-top: 0px;
        margin-right: 12px;
        position: relative;
        float: right;
        margin-left: -29%;
    }
    .navigation-bar-scrolled .fh-search-suggest-wrapper .minisearch__control--submit {
        top: 4px;
        right: -19px;
        background: url(/media/wysiwyg/Mountfords/icons/search.png) no-repeat;
        display: none;
        width: 26px;
        height: 26px;
        background-size: contain;
        outline: 0;
        position: absolute;
    }
    .navigation-bar-scrolled .search-suggest-icon-wrapper {
        display: inline-block;
    }

    .navigation-bar-scrolled .search-suggest-icon {
        outline: 0;
        padding-right: 10px;
    }

    .navigation-bar-scrolled .search-suggest-icon:before {
        background: url(/media/wysiwyg/Mountfords/icons/search.png) no-repeat;
        display: block;
        width: 26px;
        height: 24px;
        background-size: contain;
        outline: 0;
        content: "" !important;
    }

    .navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.wishlist a::before {
        margin: 4px 0 0 0;
    }
    .navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart a::before {
        margin-top: 4px;
    }
    .navigation-bar-scrolled #primary-navigation {
        margin-left: 11px;
    }
}

