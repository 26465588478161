/*------------------------------------*\
    # LOCAL COMPONENT SETTINGS
\*------------------------------------*/

/**
 * Overrides for any partials' default settings go here.
 **/

/* = Base = */


$product-list-brand-display: block;


/* = Objects = */





/* = Components = */
$color-header-background: $color-mountfords-grey;
$color-background-counter-number: $color-mountfords-orange;
$color-site-footer-subscribe: $color-mountfords-grey;
$widget-background-color: $color-mountfords-lightgrey;
$widget-button-border: 3px solid #fff;

/* = Trumps = */
