#js-aligent_fredhopper-autocomplete-search {
    border: 0;
    border-bottom: 2px solid $color-mountfords-brown;
    padding: 0 0 1px 0;
    width: 130px;
    height: 25px;
    letter-spacing: 2px;
    font-size: 14px;
    margin-right: 20px;
}

#js-aligent_fredhopper-autocomplete-wrapper .minisearch__control--submit {
    color: $color-mountfords-brown;
}

@media (max-width: 960px) {
    #js-aligent_fredhopper-autocomplete-results {
        position: static !important;
        border: 1px solid #ffffff;
        padding: 0 20px !important;
    }
}

@media (min-width: 960px) {
    .minisearch__control--submit:after {
        content: "";
        display: none;
    }

    #js-aligent_fredhopper-autocomplete-wrapper {
        width: 25vw !important;
        right: -785px;
        text-align: end !important;

        .minisearch__control--submit {
            background: url("/media/wysiwyg/Mountfords/icons/search.png") no-repeat;
            display: block;
            width: 26px;
            height: 24px;
            background-size: contain;
            outline: 0;
            right: -172px;
            top: -3px;
        }
    }

    #js-aligent_fredhopper-autocomplete-results {
        right: 0 !important;
        border: 1px solid $color-mountfords-brown;
        width: 500px !important;
        padding: 15px !important;
        top: 34px;
        margin-right: 22px;
    }

    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-results {
        margin-right: 0px;
    }

    #js-aligent_fredhopper-autocomplete-search {
        outline: 0px;
        top: -7px;
        position: absolute;
    }

    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results {
        color: #000;
        text-align: left;
        text-transform: capitalize;
    }

    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-wrapper h3 {
        margin-top: 1rem;
    }

    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords,
    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories {
        display: block;
    }

    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords .suggest_keyword,
    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories .suggest-category {
        display: list-item;
    }

    .topInfoBarContainer #js-aligent_fredhopper-autocomplete-results-3products .suggest_products {
        margin-left: 0;
        margin-bottom: 0;
        text-align: center;
    }
    .navigation-bar-scrolled .fh-search-suggest-wrapper,
    .navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
        margin-right: 0px !important;
    }
}
