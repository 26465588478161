.fis-enabled .product-info__main .product-info__social {
    display: flex;
    flex-flow: row wrap;
}

.fis-enabled .product-info__main .product-info__social > * {
    flex: 1 100%;
}

.fis-enabled .product-info__main .product-info__social .find-in-store {
    flex: 1 0 0;
    padding: 5px;
}

.fis-enabled .product-info__main .product-info__social .product-addto-links {
    flex: 1 0 0;
}

.fis-enabled .product-info__main .product-addto-links .towishlist:before {
    margin-right: 3px;
}