/*------------------------------------*\
    # LOCAL GENERAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
    ## COLOURS
\*------------------------------------*/

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/

$color-mountfords-grey: #4a4b4f;
$color-mountfords-lightgrey: #c0c0c0;
$color-mountfords-orange: #fd4e1f;
$color-mountfords-brown: #000000;
$color-mountfords-lightbrown: #bfbfbf;
$color-mountfords-cream: #bfbfbf;

$color-navigation-background: #fff;
$color-navigation-border: #000;
$color-navigation-text: #000;
$color-top-info-bar-text: #fff;
$color-background: #fcfcfc;
$color-background-content: #fff;
$color-links: inherit;
$color-text: #333;
$color-shop-accent: $color-mountfords-orange;
$color-sale: #ff0000;
$color-menu-items-border: #d3d3d3;
$color-site-footer-subscribe-text: #fff;
$color-background-footer-sitemap-contact: #fff;
$color-swatch-border: $color-mountfords-orange;
$return-button-colour: $color-mountfords-orange;
$color-light-hover: #FD4E1F1a;
$address-validation-hover: $color-light-hover;

/*------------------------------------*\
    ## FONTS
\*------------------------------------*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

$font-family-base: "Calibri Regular", Verdana, serif;
$font-family-base-functional: "Calibri Regular", Verdana, sans-serif;
$font-family-headings: "Calibri Regular", Verdana, sans-serif;

/* in Cinori we need to overide the components icon font to use our own set. */
$font-family-components-header: "munro-icons";
/* we also need to override the icon codes */
$wishlist-icon: "\e905";
$minicart-icon: "\e902";

/*------------------------------------*\
    ## LAYOUT
\*------------------------------------*/

/**
 * Any Layout related overrides can be defined in here
 **/

/* Stock Remaining Message */
.product__stock-message {
    color: $color-mountfords-orange;
}
